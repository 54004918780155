/*																		|
|																		|
|	 .d8888b.                                                        	|
|	d88P  Y88b                                                       	|
|	888    888                                                       	|
|	888         .d88b.  88888b.   .d88b.  888  888 888  888 .d8888b  	|
|	888        d88""88b 888 "88b d8P  Y8b `Y8bd8P' 888  888 88K      	|
|	888    888 888  888 888  888 88888888   X88K   888  888 "Y8888b. 	|
|	Y88b  d88P Y88..88P 888  888 Y8b.     .d8""8b. Y88b 888      X88 	|
|	 "Y8888P"   "Y88P"  888  888  "Y8888  888  888  "Y88888  88888P' 	|
|	                                                    888          	|
|	                                               Y8b d88P          	|
|	                                                "Y88P"           	|
*/
/**
 * @fileoverview
 * APP
 * This core component configures application routes, handles user authentication, uses FingerprintJS to generate a unique browser identifier and handles language switching. It also ensures that the necessary styles and components are imported and available for the application.
 * @module App
 * @author Braulio Rodriguez <brauliorg@gmail.com>
 * @version 0.1.5
 * @requires React
 * @requires react-router-dom
 * @requires react-i18next
 */

import React, { lazy, memo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Pages
const Index = lazy(() => import('./views/index/Index'));
const Page404 = lazy(() => import('./views/pages/page404/Page404'));

/**
 * Main App component.
 * @function
 * @returns {JSX.Element} The rendered App component.
 */
const App = memo(() => {
    //Change Language
    if (localStorage.getItem("datauser")){ //If the user's default language is different from the stored one, it changes it.
        const datauser = JSON.parse(localStorage.getItem("datauser"));
        if(datauser !== localStorage.getItem("userLanguage")){
            localStorage.setItem("userLanguage", datauser.language);
        };
    };
    const [, i18n] = useTranslation("global");
    if(i18n.language !== localStorage.getItem("userLanguage")){
        const lastSelectedLanguage = localStorage.getItem("userLanguage");
        i18n.changeLanguage(lastSelectedLanguage);
    };
    //Change Language

    // Define the base path for the BrowserRouter
    const publicPath = window.publicPath;;
    return (
        <BrowserRouter basename={publicPath}>
            <Routes>
                <Route exact path="/" name="Index Page" element={<Index />} />
                <Route exact path="*" name="Error 404" element={<Page404 />} />
            </Routes>
        </BrowserRouter>
    );
});

export default App;
