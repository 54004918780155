/**
 * @fileoverview
 * This code defines and configures a Redux store using the @reduxjs/toolkit tool.
 * This Redux store is used to manage the state of the application. In this case, the initial state has a sidebarShow property that could be used to control whether the UI sidebar is visible or not. The reducer allows this state to be updated through specific actions, such as the 'set' action. This pattern provides a mechanism to centrally manage and update the state of the application.
 * @author Braulio Rodriguez <brauliorg@gmail.com>
 * @version 0.1.5
 * @requires @reduxjs/toolkit
 */

import { configureStore } from "@reduxjs/toolkit";

/**
 * Initial state for the Redux store.
 * @type {Object}
 */
const initialState = {
    sidebarShow: true,
};

/**
 * Reducer function to handle state changes.
 * @param {Object} state - Current state.
 * @param {Object} action - Action object.
 * @returns {Object} New state after handling the action.
 */
const changeState = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case 'set':
            return { ...state, ...rest };
        default:
            return state;
    };
};

/**
 * Redux store configured with the provided reducer.
 * @type {Object}
 */
const store = configureStore({ reducer: changeState });

export default store;
